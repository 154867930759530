<template>
  <div v-if="contractObject !== null">
    <b-row>
      <b-col md="6">
        <b-card
          title="User Information"
          class="position-static"
        >
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Lead Name">
                    <b-form-input
                      v-model="contractObject.lead.name"
                      placeholder="Lead Name"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Mobile">
                    <b-form-input
                      v-model="contractObject.lead.mobile"
                      placeholder="Mobile"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Lead Full Name">
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required"
                    >
                      <b-form-input
                        v-model="contractObject.full_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Lead Full Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Broker Name">
                    <b-form-input
                      v-model="contractObject.broker.name"
                      placeholder="Broker Name"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Sales Advisor Name">
                    <b-form-input
                      v-model="contractObject.agent.name"
                      placeholder="Sales Advisor Name"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Contract Status">
                    <b-form-input
                      v-model="contractObject.status"
                      placeholder="Status"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card
          title="Contract Activity"
          class="position-static"
          style="height: 323px; overflow-y: scroll;"
        >
          <app-timeline>

            <!-- 12 INVOICES HAVE BEEN PAID -->
            <app-timeline-item
              v-for="(item) in contractItem.activities"
              :key="item.id"
              variant="primary"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ item.title }}</h6>
                <small class="text-muted">{{ item.briefDate }}</small>
              </div>
              <p>{{ item.description }}</p>
              <b-button
                v-b-toggle.report-list
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="openUpdateActivityModal(item.excelDate, item.id)"
              >
                Update Creation Date
              </b-button>
            </app-timeline-item></app-timeline>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      title="Contract Details"
      class="position-static"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group label="Insurance Type">
                <validation-provider
                  #default="{ errors }"
                  name="Insurance Type"
                  rules="required"
                >
                  <v-select
                    v-model="contractObject.typeID"
                    :options="insuranceTypeList"
                    label="title"
                    :reduce="title => title.id"
                    @input="loadProductsAndProviders(contractObject.typeID)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Insurance Provider">
                <validation-provider
                  #default="{ errors }"
                  name="Insurance Type"
                  rules="required"
                >
                  <v-select
                    v-model="contractObject.providerID"
                    :options="insuranceProvidersList"
                    label="title"
                    :reduce="title => title.id"
                    placeholder="Select Provider"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Insurance Product">
                <validation-provider
                  #default="{ errors }"
                  name="Insurance Product"
                  rules="required"
                >
                  <v-select
                    v-model="contractObject.productID"
                    :options="insuranceProductsList"
                    label="title"
                    :reduce="title => title.id"
                    placeholder="Select Product"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="contractObject.statusGroup === 3 || contractObject.statusGroup === 2"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="BI Number"
                rules=""
              >
                <b-form-group label="BI Number">
                  <b-form-input
                    v-model="contractObject.biNumber"
                    placeholder="BI Number"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-col>
            <b-col md="4">
              <b-form-group label="Lives">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    v-model="contractObject.lives"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Lives"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Insurance Currency">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="contractObject.currencyID"
                    :options="currenciesList"
                    label="name"
                    :reduce="name => name.id"
                    placeholder="Select Currency"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Frequency">
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <v-select
                    v-model="contractObject.frequencyID"
                    :options="paymentFrequenciesList"
                    label="name"
                    :reduce="name => name.id"
                    placeholder="Select Frequency"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Annual Premium">
                <validation-provider
                  #default="{ errors }"
                  name="Annual Premium"
                  rules="required"
                >
                  <b-form-input
                    v-model="contractObject.premium_amount"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Premium"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="contractObject.statusGroup === 3 || contractObject.statusGroup === 2"
              md="4"
            >
              <b-form-group label="Start Date">
                <b-form-datepicker
                  v-model="contractObject.startDate"
                  class="mb-1"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="contractObject.statusGroup === 0"
              md="4"
            >
              <b-form-group label="TIA Date">
                <validation-provider
                  #default="{ errors }"
                  name="TIA Date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="contractObject.created_at"
                    :state="errors.length > 0 ? false:null"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="contractObject.statusGroup === 0"
              md="12"
            >
              <b-form-group label="Description">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules=""
                >
                  <b-form-textarea
                    id="textarea-small"
                    v-model="contractObject.description"
                    size="sm"
                    placeholder="Contract Description (optional)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="demo-inline-spacing"
            >
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="saveContractDetailsFunction(true)"
              >
                Save
              </b-button>
              <b-button
                v-if="contractObject.statusGroup === 0"
                variant="outline-primary"
                type="submit"
                @click.prevent="updateStatus(3)"
              >
                Convert to TIA
              </b-button>
              <b-button
                v-else-if="contractObject.statusGroup === 3 || contractObject.statusGroup === 2"
                variant="outline-primary"
                type="submit"
                @click.prevent="updateStatus(1)"
              >
                Convert to active
              </b-button>
              <b-button
                v-else-if="contractObject.statusGroup === 1"
                variant="outline-primary"
                type="submit"
                @click.prevent="updateStatus(2)"
              >
                Convert to lapsed
              </b-button>

              <b-button
                v-if="contractObject.statusGroup === 0 || contractObject.statusGroup === 3"
                variant="outline-primary"
                type="submit"
                @click.prevent="updateStatus(2)"
              >
                Convert to lapsed
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-modal
      v-model="showModal"
      centered
      title="Update Activity Date"
      ok-only
      hide-backdrop
      no-close-on-backdrop
      ok-title="Update"
      @ok="updateActivityDate"
    >
      <b-card-text>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group label="Creation Date">
                  <validation-provider
                    #default="{ errors }"
                    name="Creation Date"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="updatedActivityDate"
                      :state="errors.length > 0 ? false:null"
                      class="mb-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { required, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'EditContract',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    vSelect,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
  },
  data() {
    return {
      emailValue: '',
      name: '',
      contractObject: {},
      showModal: false,
      updatedActivityDate: null,
      updatedActivityID: null,
      updateObject: {
        form: {},
        url: `admin/contracts/${this.$route.params.contract_id}`,
        storeName: 'contracts',
      },
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      insuranceTypeList: 'insuranceTypes/insuranceTypeList',
      insuranceProvidersList: 'insuranceProviders/insuranceProviderList',
      insuranceProductsList: 'insuranceProducts/insuranceProductList',
      currenciesList: 'currencies/currencyList',
      paymentFrequenciesList: 'paymentFrequencies/paymentFrequenciesList',
      contractItem: 'contracts/contractItem',
    }),
  },
  async created() {
    await this.loadContractDetailsFunction()
    await this.loadAllInsuranceTypesFunction()
    await this.loadAllInsuranceCurrenciesFunction()
    await this.loadAllInsuranceProvidersFunction(this.contractObject.typeID)
    await this.loadAllInsuranceProductsFunction(this.contractObject.typeID)
    await this.loadAllPaymentFrequenciesFunction()
  },
  methods: {
    ...mapActions({
      loadContractDetails: 'get',
      loadAllInsuranceTypes: 'get',
      loadAllInsuranceProviders: 'get',
      loadAllInsuranceProducts: 'get',
      loadAllInsuranceCurrencies: 'get',
      loadAllPaymentFrequencies: 'get',
      updateContract: 'add',
      add: 'add',
    }),
    async loadAllInsuranceTypesFunction() {
      await this.loadAllInsuranceTypes({
        storeName: 'insuranceTypes',
        url: '/admin/settings/insurance/types',
        commitUrl: 'insuranceTypes/SET_INSURANCE_TYPE',
      })
    },
    async loadAllPaymentFrequenciesFunction() {
      await this.loadAllPaymentFrequencies({
        storeName: 'paymentFrequencies',
        url: '/admin/settings/frequencies',
        commitUrl: 'paymentFrequencies/SET_PAYMENT_FREQUENCIES',
      })
    },
    async loadAllInsuranceProvidersFunction(typeID) {
      await this.loadAllInsuranceProviders({
        storeName: 'insuranceProviders',
        url: `/admin/settings/insurance/providers/${typeID}`,
        commitUrl: 'insuranceProviders/SET_INSURANCE_PROVIDERS',
      })
    },
    async loadAllInsuranceProductsFunction(typeID) {
      await this.loadAllInsuranceProducts({
        storeName: 'insuranceProducts',
        url: `/admin/settings/insurance/products/${typeID}`,
        commitUrl: 'insuranceProducts/SET_INSURANCE_PRODUCTS',
      })
    },
    async loadContractDetailsFunction() {
      await this.loadContractDetails({
        storeName: 'contracts',
        url: `/admin/contracts/${this.$route.params.contract_id}/details`,
        commitUrl: 'contracts/SET_CONTRACT',
      })
      this.contractObject = this.contractItem
    },
    async loadAllInsuranceCurrenciesFunction() {
      await this.loadAllInsuranceCurrencies({
        storeName: 'currencies',
        url: '/admin/settings/currency',
        commitUrl: 'currencies/SET_CURRENCIES',
      })
    },
    async loadProductsAndProviders(typeID) {
      this.contractObject.providerID = 0
      this.contractObject.productID = 0
      await this.loadAllInsuranceProvidersFunction(typeID)
      await this.loadAllInsuranceProductsFunction(typeID)
    },
    openUpdateActivityModal(date, id) {
      this.updatedActivityDate = date
      this.updatedActivityID = id
      this.showModal = true
    },
    async updateActivityDate() {
      this.add({
        form: {
          created_at: this.updatedActivityDate,
        },
        url: `admin/activity/update/${this.updatedActivityID}`,
      }).then(async response => {
        this.$router.go(-1)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: response.data.message,
          },
        })
      })
    },
    async updateStatus(statusGroupID) {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const firstObject = {
            statusGroup: statusGroupID,
            // eslint-disable-next-line no-nested-ternary
            type: statusGroupID === 1 ? 'contract_active' : statusGroupID === 2 ? 'contract_lapsed' : statusGroupID === 3 ? 'contract_tia' : '',
          }
          this.updateContract({
            form: firstObject,
            url: `admin/contracts/${this.$route.params.contract_id}`,
            storeName: 'contracts',
          }).then(async response => {
            // this.contractObject.statusGroup = statusGroupID
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            await this.saveContractDetailsFunction(false)
            this.$router.go(-1)
            // await this.loadContractDetailsFunction()
          }).catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: error,
              },
            })
          })
        }
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateObject.form.full_name = this.contractObject.full_name
          this.updateObject.form.insurance_type_id = this.contractObject.typeID
          this.updateObject.form.insurance_provider_id = this.contractObject.providerID
          this.updateObject.form.insurance_product_id = this.contractObject.productID
          this.updateObject.form.currency_id = this.contractObject.currencyID
          this.updateObject.form.frequency_id = this.contractObject.frequencyID
          this.updateObject.form.lives = this.contractObject.lives
          this.updateObject.form.premium_amount = this.contractObject.premium_amount
          this.updateObject.form.description = this.contractObject.description
          if (this.contractObject.statusGroup === 3 || this.contractObject.statusGroup === 2) {
            this.updateObject.form.startDate = this.contractObject.startDate
            this.updateObject.form.biNumber = this.contractObject.biNumber
          }
          return true
        }
        return false
      })
    },
    saveContractDetailsFunction(showAlert) {
      this.validationForm()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateContract(this.updateObject).then(response => {
            if (showAlert) {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            }
          }).catch(error => {
            if (showAlert) {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
